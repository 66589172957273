import { Buffer } from 'buffer';


import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App.js';
import reportWebVitals from './reportWebVitals.js';
// import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';

import { UserContext, UserContextProvider } from './UserContext.js'; // Импортируем UserProvider


const root = ReactDOM.createRoot(document.getElementById('root'));


root.render(
  
  <React.StrictMode>
       <UserContextProvider> {/* Оборачиваем приложение в UserProvider */}
        {/* <div style={{ backgroundColor: '#383a3b', position: 'fixed', zIndex: "100", top: 0, left: 0, right: 0, height: 'calc(var(--tg-content-safe-area-inset-top) + var(--tg-safe-area-inset-top))' }}></div> */}
      <div className="bg-dark">
        <div className="row">
          <div className="align-items-center">
            
            <App />
          </div>
        </div>
      </div>
    </UserContextProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
