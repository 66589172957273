import React, { useState, useRef, useContext } from "react";
import WalletConnectButton from "./WalletConnectButton.js";
import { useTonConnect } from "../Hook/useTonconnect.ts";
import { RefreshCcw } from 'lucide-react';
import useNft from "../Hook/useNft.ts";
import { UserContext } from "../UserContext.js";
import { ToastContainer, toast } from "react-toastify";

const Assets = () => {
    const {tonAddress, connected} = useTonConnect();
    const {fetchNfts} = useNft();
    const {isLoading, setIsLoading} = useContext(UserContext);
    const attemptCountRef = useRef(1);
    
    const handleRefresh = async () => {
        setIsLoading(true);
        await fetchNfts();
       

        // Увеличиваем время блокировки на 5 секунд с каждой попыткой
        const blockTime = attemptCountRef.current * 5000;
        
        setTimeout(() => {
            setIsLoading(false);
            attemptCountRef.current += 1;
        }, blockTime);
    };

    return (

        
        <div className='min-h-[100dvh] bg-gradient-to-b from-[#1a0b2e] to-[#0f0618] text-white p-2' style={{ position: 'relative' }}>
           <div className='text-center text-2xl mb-2 mt-4 flex items-center justify-center gap-2'>
                Assets
                <button 
                    onClick={handleRefresh}
                    disabled={isLoading}
                    className={`p-1 hover:bg-gray-700 rounded-full transition-colors mt-2 
                        ${isLoading ? ' cursor-not-allowed' : ''}`}
                >
                    <RefreshCcw className={`h-4 w-4  ${isLoading ? 'animate-spin text-orange-300' : ''}`} />
                </button>
           </div>
           {isLoading && (

            <div className="Toastify">
                <div className="Toastify__toast-container Toastify__toast-container--top-center">
                    <div id="1" data-in="true" className="Toastify__toast Toastify__toast-theme--dark Toastify__toast--default">
                        <div role="alert" className="Toastify__toast-body">
                            <div className="Toastify__toast-icon">
                                <div className="Toastify__spinner"></div>
                            </div>
                            <div>Waiting for confirmation...</div>
                        </div>
                        <button 
                            className="Toastify__close-button Toastify__close-button--dark" 
                            type="button" 
                            onClick={() => setIsLoading(false)}
                        >
                            <span className="text-xl m-2">×</span>
                        </button>
                    </div>
                </div>
            </div>              
           )}
           {!connected && (<div className='text-center  mb-4 text-gray-400'>You can see your assets here.</div>)}
            <WalletConnectButton />
            {!connected && 
            <div className="text-center text-sm mb-4 text-gray-400">
                Connect your wallet to see your assets.
            </div>}
        </div>
    );
};

export default Assets;