import React from 'react';

function Clubs() {
    return (
        <div className='min-h-[100dvh] bg-gradient-to-b from-[#1a0b2e] to-[#0f0618] text-white p-4' style={{ position: 'relative' }}>
            <div>Clubs</div>
            <div className='fixed top-1/2 left-1/2 font-bold text-2xl transform -translate-x-1/2 -translate-y-1/2 p-4 text-center bg-opacity-80  animate-pulse duration-1000'>
                Coming soon
            </div>
        </div>
    );
}

export default Clubs;
