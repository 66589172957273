import {useTonConnect} from "./useTonconnect.ts";
import { UserContext } from "../UserContext.js";
import { useContext } from "react";
import { Address } from "@ton/core";

interface NftItem {
    collection?: {
        address: string;
    };
    [key: string]: any;
}

export default function useNft() {
    const {wallet, network} = useTonConnect();
    const {setNfts} = useContext(UserContext) as {setNfts: (nfts: any[]) => void};

    const fetchNfts = async () => {
        let link = '';
        if (network === 'Testnet') {
            link = `https://testnet.tonapi.io/v2/accounts/${wallet}/nfts`;
        } else {
            link = `https://tonapi.io/v2/accounts/${wallet}/nfts`;
        }
        
        try {
            const response = await fetch(link, {
                headers: {
                    'Authorization': `Bearer AGHVJNG57RBRJAIAAAADQCFQIS4AUEOXRI6DYLIJVWS33MIESA3RENAUNTCE4G4YGH5T67Q`,
                    'Accept': 'application/json',
                }
            });
            
            const data = await response.json();
            
            const allowedCollections = [
                "0:f70054bb417e1fe93b349651caccb10f36583b9990e33fa0e893cdf5d7a03a38",
            ];
            const allowedVaucher = [
                "0:83a67d0dcb1688f1442fe91aaf8e88c0c7cee875a56f0dd9f1c41eec3d04dc5f"                
            ];

            const filteredCollectionNfts = data.nft_items.filter((nft: NftItem) => 
                allowedCollections.includes(nft.collection?.address)
            );
            
            const filteredVoucherNfts = data.nft_items.filter((nft: NftItem) => 
                allowedVaucher.includes(nft.collection?.address)
            );
            
            const processedCollectionNfts = filteredCollectionNfts.map(nft => ({
                ...nft,
                type: 'nft'
            }));
            
            const processedVoucherNfts = filteredVoucherNfts.map(nft => ({
                ...nft,
                type: 'voucher'
            }));
            
            const allNfts = [...processedCollectionNfts, ...processedVoucherNfts];
            
            const sortedNfts = allNfts.sort((a, b) => {
                const nameA = a.metadata?.name?.toLowerCase() || '';
                const nameB = b.metadata?.name?.toLowerCase() || '';
                return nameA.localeCompare(nameB);
            });
            
            setNfts(sortedNfts);
            return sortedNfts;
            
        } catch (error) {
            console.error("Ошибка при получении токенов:", error);
            return [];
        }
    }
 
    return { fetchNfts }; 
}
